import { UPDATE_TICKS, SUBSCRIBE, UNSUBSCRIBE, REPLACE_SUBSCRIBED_TOKENS } from './actionTypes'
// import { exchange_mapping } from '../../utils/common'

export const updateTicks = (ticks) => {
  return {
    type: UPDATE_TICKS,
    ticks
  }
}

export const subscribe = (tokens, token_keys = {}, kind = 'subscribe') => {
  return {
    type: SUBSCRIBE,
    tokens,
    token_keys,
    kind
  }
}

export const unSubscribe = () => {
  return {
    type: UNSUBSCRIBE
  }
}

export const advanceSubscribe = (searchTokens, replaceTokens) => {
  return {
    type: REPLACE_SUBSCRIBED_TOKENS,
    removeTokens: searchTokens,
    addTokens: replaceTokens,
  }
}


export const getTokenKeysFromTokens = (tokens = []) => {
  const token_keys = {}
  if (tokens && tokens.length) {
    tokens.forEach((token) => {
      token_keys[token] = 1
    })
  }
  return token_keys
}
