import { call, put, takeLatest } from 'redux-saga/effects'
import { baseTechURL, fetchBacktestChart, fetchMarketplaceChart, openStreakURL } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import { fetchBacktestDataFailure, fetchBacktestDataSuccess, changeSymbol } from './actions'
import { advanceSubscribe } from '../Ticks/actions'
import { technicalPairs } from '../Instruments/actions'
import { FETCH_BACKTEST_CHART, RUN_BACKTEST, SET_SYMBOL_AND_SUBSCRIBE } from './actionTypes'
import { STREAK_PLATFORM } from '../../utils/constants'

function* fetchBacktestData(action) {
  try {
    let url
    const { params, headers, isDiscover } = action.payload
    const { platform = 'INDIA'} =  params
    const baseUrl = platform === STREAK_PLATFORM.OPENSTREAK ? openStreakURL : baseTechURL
    const seg_sym = decodeURIComponent(params?.seg_sym)
    if (isDiscover === 'true' && platform !== STREAK_PLATFORM.OPENSTREAK) {
      url = baseUrl + fetchMarketplaceChart
    } else url = baseUrl + fetchBacktestChart

    if (platform === STREAK_PLATFORM.OPENSTREAK){
      if(isDiscover == 'true'){
        params['algo_uuid']= params['publishing_uuid']
        delete params['publishing_uuid']
      } else {
        params['algo_uuid'] = params['algoId']
        delete params['algoId']
      }

      params['sample'] = !!isDiscover
    }

    const data = yield call(getRequest, url, params, headers)
    if (data.status === 'success') {
      yield put(fetchBacktestDataSuccess(data.backtests[0].backtest_result[seg_sym]))
    } else {
      yield put(fetchBacktestDataFailure(data.error))
    }
  } catch (err) {
    yield put(fetchBacktestDataFailure(err))
  }
}

export function* fetchBacktestDataSaga() {
  yield takeLatest(FETCH_BACKTEST_CHART, fetchBacktestData)
}

function* runBacktest(action) {
  try {
    const url = 'https://ctb.streak.tech/format/submit'
    const { params, headers } = action
    const { panes, interval, seg_sym, broker, candle } = params
    const formData = new FormData()
    formData.append('chart_format', 'trading_view')
    formData.append('candle_interval', interval)
    formData.append('stocks', seg_sym)
    formData.append('chart_type', candle)
    formData.append('broker_name', broker)
    formData.append('payload', JSON.stringify(panes))
    const data = yield call(postRequest, url, formData, {}, false)
    if (data.status === 'success') {
      // yield put(fetchBacktestDataSuccess(data.backtests[0].backtest_result[params.seg_sym].trade_log))
      // console.log('sucess')
    } else {
      // yield put(fetchBacktestDataFailure(data.error))
      // console.log('error', data.status)
    }
  } catch (err) {
    // yield put(fetchBacktestDataFailure(err))
    // console.log('error', err)
  }
}

export function* runBacktestSaga() {
  yield takeLatest(RUN_BACKTEST, runBacktest)
}

function* setSymbolAndSubscribe(action) {
  const { symbol, dispatch } = action
  const seg_sym = symbol.replace(':', '_')
  const prevSegSymArray = []
  yield put(changeSymbol(symbol)) // here we store in seg:sym format
  // yield put(technicalPairs(dispatch, [seg_sym]))
}

export function* setSymbolAndSubscribeSaga() {
  yield takeLatest(SET_SYMBOL_AND_SUBSCRIBE, setSymbolAndSubscribe)
}

