export const resolutionMapping = {
  1: 'min',
  3: '3min',
  5: '5min',
  10: '10min',
  15: '15min',
  30: '30min',
  60: 'hour',
  D: 'day',
  '1D': 'day',
}
export const resolutionMappingIndia = {
  1: '1MIN',
  3: '3MIN',
  5: '5MIN',
  10: '10MIN',
  15: '15MIN',
  30: '30MIN',
  60: 'HOUR',
  D: 'DAY',
  '1D': 'DAY',
}

export const THEME_TYPE_MAP = {
  DARK: {
    label: 'Dark', value: 'DARK', iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  LIGHT: {
    label: 'Light', value: 'LIGHT', iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  AUTO: {
    label: 'Auto', value: 'AUTO', iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
}

export const DISPLAY_DEVICE = {
  MOBILE: {
    value: 0, valueKey: 'mobile',
  },
  TAB: {
    value: 1, valueKey: 'tab',
  },
  DESKTOP: {
    value: 2, valueKey: 'desktop',
  },
  FHD: {
    value: 3, valueKey: '2k',
  },
  UHD: {
    value: 4, valueKey: '4k',
  },
}

export const candleIntervals = {
  '1 Minute': 'min',
  '3 Minutes': '3min',
  '5 Minutes': '5min',
  '10 Minutes': '10min',
  '15 Minutes': '15min',
  '30 Minutes': '30min',
  '1 Hour': 'hour',
  '1 Day': 'day',
}

export const candleIntervalsShort = {
  min: '1m',
  '3min': '3m',
  '5min': '5m',
  '10min': '10m',
  '15min': '15m',
  '30min': '30m',
  hour: '1H',
  day: '1D',
}

export const condensed_candle_map = {
  min: 'min',
  '3min': '3min',
  '5min': '5min',
  '10min': '10min',
  '15min': '15min',
  '30min': '30min',
  hour: 'hour',
  day: 'day',
  '1 Minute': 'min',
  '3 Minutes': '3min',
  '5 Minutes': '5min',
  '10 Minutes': '10min',
  '15 Minutes': '15min',
  '30 Minutes': '30min',
  '1 Hour': 'hour',
  '1 Day': 'day',
  '1m': 'min',
  '3m': '3min',
  '5m': '5min',
  '10m': '10min',
  '15m': '15min',
  '30m': '30min',
  '1H': 'hour',
  '1D': 'day',
}

export const technicalslabelMap = {
  Bearish: {
    MA: {
      text: '#FE9801', bg: '#FFF5E6', bgDark: '#312C25', icon: 'BEARISH',
    },
    DEFAULT: {
      text: '#EB1D54', bg: 'redLightBg', bgDark: 'redLightBg', icon: 'BEARISH',
    },
    OSCILLATOR: {
      text: '#FF667D', bg: '#FCE7EC', bgDark: 'redLightBg', icon: 'BEARISH',
    },
  },
  Neutral: {
    MA: {
      text: '#7B828B', bg: '#EBEFF5', bgDark: 'greyLightBg', icon: 'NEUTRAL',
    },
    DEFAULT: {
      text: '#7B828B', bg: '#EBEFF5', bgDark: 'greyLightBg', icon: 'NEUTRAL',
    },
    OSCILLATOR: {
      text: '#7B828B', bg: '#EBEFF5', bgDark: 'greyLightBg', icon: 'NEUTRAL',
    },
  },
  Bullish: {
    MA: {
      text: '#018EE8', bg: '#E0F3FF', bgDark: '#273138', icon: 'BULLISH',
    },
    DEFAULT: {
      text: '#3CBB00', bg: '#E2FFD5', bgDark: 'greenLightBg', icon: 'BULLISH',
    },
    OSCILLATOR: {
      text: '#034DE6', bg: '#E3ECFF', bgDark: '#313643', icon: 'BULLISH',
    },
  },
}

export const SAMPLES_DISP_LIMIT = 10
export const POSITION_SIZING_TYPE = {
  DEFAULT: '-',
  CAPITAL_BASED: 'Capital based',
  RISK_BASED: 'Risk based',
}
export const POSITION_SIZING_TYPES = Object.values(POSITION_SIZING_TYPE)

export const holding_type_map = {
  MIS: 'MIS',
  'CNC/NRML': 'CNC/NRML',
  CNC: 'CNC/NRML',
}

export const error_msg = 'Error getting response'

// timestamp in seconds ( value / ( 60 * 60 * 24)) days
export const dateRangeEpochs = {
  min: 2505600,
  '3min': 7689600,
  '5min': 7689600,
  '10min': 7689600,
  '15min': 7689600,
  '30min': 7689600,
  hour: 31449600,
  day: 157593600,
}

export const CHART_MODES = {
  LINE: 'LINE', // render line chart without sending chart data
  LINE_WITH_DATA: 'LINE_WITH_DATA', // render line chart with sending chart data
  CANDLE: 'CANDLE', // render candle chart without sending chart data
  CANDLE_WITH_LOG: 'CANDLE_WITH_LOG', // render candle chart with chart data & tradeLog
}

export const chartType = {
  LINE: 'LINE',
  CANDLE: 'CANDLE',
}

export const STREAK_PLATFORM = {
  INDIA: 'INDIA', // tech
  WORLD: 'WORLD',
  OPENSTREAK : 'OPENSTREAK',
}
