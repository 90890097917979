import { connect } from 'react-redux'

import {
  toggleTerms,
  pollUser,
  toggleRootModal,
} from './actions'

import { updateTicks } from '../Instruments/actions'

import Common from './Common'

const mapStateToProps = (state) => {
  return {
    ...state.common,
    tokens: state.instruments.tokens,
    unSubList: state.instruments.unSubList,
    seg_sym_list: state.instruments.seg_sym_list,
    unSubscribe: state.instruments.unSubscribe,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTicks: ticks => dispatch(updateTicks(ticks)),
    toggleTerms: (terms_uri, heading) => dispatch(toggleTerms(terms_uri, heading)),
    pollUser: (params, headers) => dispatch(pollUser(params, headers)),
    toggleRootModal: (modalName, showModal) => dispatch(toggleRootModal(modalName, showModal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Common)
