import { condensed_candle_map, POSITION_SIZING_TYPE, holding_type_map } from './constants'

export const exchange_mapping = {
  COINBASE: 'coinbase',
  BITFINEX: 'bitfinex',
  BINANCE: 'binance',
  POLONIEX: 'poloniex',
  HUOBI: 'huobi',
  CCCAGG: 'cccagg',
  HITBTC: 'hitbtc',
  BITHUMB: 'bithumb',
  BITBANK: 'bitBank',
  COINBASEPRO: 'coinbase',
  OKEX: 'okex',
  KRAKEN: 'kraken',
  ZB: 'zb',
  BITMEX: 'bitmex',
  DERIBIT: 'deribit',
  NASDAQ: 'nasdaq',
  NYSE: 'nyse',
  'BINANCE-FUTURES': 'binance-futures',
  'KRAKEN-FUTURES': 'kraken-futures',
}

export const headerGenerator = (auth_token = '', sessionid = '') => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    authorization: `csrftoken=${auth_token};csrfmiddlewaretoken=${auth_token};sessionid=${sessionid}`,
    'x-csrftoken': sessionid,
  }
  return headers
}

export const getEncodedParams = (params) => {
  const searchParams = []
  Object.keys(params).forEach((key) => {
    const encodedKey = encodeURIComponent(key)
    if (key === 'seg_sym_list' || (params[key] && params[key].constructor.name === 'Array')) {
      const encodedValue = encodeURIComponent(JSON.stringify(params[key]))
      searchParams.push(`${encodedKey}=${encodedValue}`)
    } else {
      const encodedValue = encodeURIComponent(params[key])
      searchParams.push(`${encodedKey}=${encodedValue}`)
    }
  })
  return searchParams.join('&')
}

export const loadScript = ({ src, id }) => {
  return new Promise((resolve, reject) => {
    try {
      if (id && src) {
        const element = document.getElementById(id)
        if (element) {
          resolve()
        }
        const script = document.createElement('script')
        script.src = src
        script.id = id
        script.addEventListener('load', (e) => {
          resolve(e)
        })
        script.addEventListener('error', (e) => {
          reject(e)
        })
        document.body.appendChild(script)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getSegSymFromDC = (pair) => {
  let segSym = pair
  if (pair.includes('DYNAMIC CONTRACT')) {
    [segSym] = pair.split('(')[1].split(',')
  }
  return segSym
}

export const formatAnalysis = (analysis) => {
  let mabuy = 0
  let masell = 0
  let maneutral = 0
  let obuy = 0
  let osell = 0
  let oneutral = 0
  const newAnalysis = { ...analysis }
  const {
    rec_stochastic_k,
    rec_stochastic_rsi_fast,
    rec_ao,
    rec_adx,
    rec_macd,
    rec_mom,
    rec_cci,
    close,
    ema10,
    ema100,
    ema20,
    ema200,
    ema30,
    ema5,
    ema50,
    hma,
    rec_ichimoku,
    rec_ult_osc,
    rec_willR,
    rec_rsi,
    sma10,
    sma100,
    sma20,
    sma200,
    sma30,
    sma5,
    sma50,
    vwma,
  } = newAnalysis
  const maArr = [
    ema5,
    sma5,
    ema10,
    sma10,
    ema20,
    sma20,
    ema30,
    sma30,
    ema50,
    sma50,
    ema100,
    sma100,
    ema200,
    sma200,
    vwma,
    hma,
  ]
  maArr.forEach((item) => {
    if (item > close && item !== 0) {
      masell++
    } else if (item < close && item !== 0) {
      mabuy++
    } else {
      maneutral++
    }
  })
  if (rec_ichimoku === 1) {
    masell++
  } else if (rec_ichimoku === -1) {
    mabuy++
  } else {
    maneutral++
  }
  // for oscillators
  if (rec_rsi === 1) {
    obuy++
  } else if (rec_rsi === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_ult_osc === 1) {
    obuy++
  } else if (rec_ult_osc === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_willR === 1) {
    obuy++
  } else if (rec_willR === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_adx === 1) {
    obuy++
  } else if (rec_adx === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_ao === 1) {
    obuy++
  } else if (rec_ao === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_cci === 1) {
    obuy++
  } else if (rec_cci === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_stochastic_k === 1) {
    obuy++
  } else if (rec_stochastic_k === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_stochastic_rsi_fast === 1) {
    obuy++
  } else if (rec_stochastic_rsi_fast === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_macd === 1) {
    obuy++
  } else if (rec_macd === -1) {
    osell++
  } else {
    oneutral++
  }
  if (rec_mom === 1) {
    obuy++
  } else if (rec_mom === -1) {
    osell++
  } else {
    oneutral++
  }
  newAnalysis.mabuy = mabuy
  newAnalysis.masell = masell
  newAnalysis.maneutral = maneutral
  newAnalysis.obuy = obuy
  newAnalysis.osell = osell
  newAnalysis.oneutral = oneutral
  newAnalysis.buy = mabuy + obuy
  newAnalysis.sell = masell + osell
  newAnalysis.neutral = maneutral + oneutral
  return newAnalysis
}

export const candle_map = {
  min: '1 Minute',
  '3min': '3 Minutes',
  '5min': '5 Minutes',
  '10min': '10 Minutes',
  '15min': '15 Minutes',
  '30min': '30 Minutes',
  hour: '1 Hour',
  day: '1 Day',
}

export const algoSummary = (algoObj) => {
  if (algoObj === undefined) {
    return {
      entryStr: '',
      exitStr: '',
      deployTime: '',
      algoName: '',
      algo_name: '',
    }
  }
  const {
    action_type,
    stop_loss,
    take_profit,
    action_str_exit,
    action_str,
    quantity,
    dt_start,
    dt_stop,
    algo_name,
    time_frame,
    chart_type = 'candlestick',
    // trading_start_time = '09:00',
    // trading_stop_time = '17:00'
  } = algoObj
  let userAction = ''
  let userExit = ''
  let interval = ''
  let share = ''
  let entryStr = ''
  let exitStr = ''
  try {
    userAction = action_type.toUpperCase()
    userExit = userAction === 'BUY' ? 'SELL' : 'BUY'
    interval = candle_map[time_frame].toLowerCase()
    share = Math.abs(quantity) > 1 ? 'shares' : 'share'
    entryStr = `${userAction} ${quantity} ${share} when ${action_str} at ${interval} candle interval \
using ${chart_type} chart.`
    exitStr = `${userExit} ${quantity} ${share} at stop loss of ${stop_loss}%\
 or target profit of ${take_profit}% at ${interval} candle interval using ${chart_type} chart.`
    if (action_str_exit !== '') {
      exitStr = `${userExit} ${quantity} ${share} when ${action_str_exit} or at stop loss of ${stop_loss}% or\
 target profit of ${take_profit}%  at ${interval} candle interval using ${chart_type} chart.`
    }
  } catch (err) { } // eslint-disable-line
  return {
    entryStr,
    exitStr,
    deployTime: dt_stop || dt_start,
    algoName: algo_name,
    algo_name,
  }
}

export const parseQuery = (str) => {
  const queries = {}
  let query = str.substring(1)
  query = query.split('&')
  for (let j = 0; j < query.length; j++) {
    const [key, value] = query[j].split('=')
    queries[key] = escape(decodeURIComponent(value))
  }
  return queries
}

export const getCookies = () => {
  let cookies = decodeURIComponent(document.cookie)
  cookies = cookies.split(';')
  const cookie_obj = {}
  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].split('=')
    if (key && value) {
      cookie_obj[key.trim()] = value.trim()
    }
  }
  return cookie_obj
}

// export const getEncodedParams = (params) => {
//   const searchParams = []
//   Object.keys(params).forEach((key) => {
//     const encodedKey = encodeURIComponent(key)
//     if (key === 'seg_sym_list' || (params[key] && params[key].constructor.name === 'Array')) {
//       const encodedValue = encodeURIComponent(JSON.stringify(params[key]))
//       searchParams.push(`${encodedKey}=${encodedValue}`)
//     } else {
//       const encodedValue = encodeURIComponent(params[key])
//       searchParams.push(`${encodedKey}=${encodedValue}`)
//     }
//   })
//   return searchParams.join('&')
// }

export const getCandleIntervalsMap = (fullMap, shortMap) => {
  return Object.keys(fullMap).map((item) => {
    return ({
      label: item, value: condensed_candle_map[item], shortLabel: shortMap[item],
    })
  })
}

export const algoSummaryGenerator = (algoObj) => {
  const {
    dt_start,
    dt_stop,
    algo_name,
    chart_type = 'candlestick',
    updated_at,
  } = algoObj
  const { entryStr, exitStr, interval } = formEntryExitString(algoObj)

  return {
    entryStr,
    exitStr,
    deployTime: dt_stop || dt_start || updated_at,
    algoName: algo_name,
    algo_name,
    chart_type,
    interval,
  }
}

export const formEntryExitString = (algoObj) => {
  const {
    position_type, positionType, ip_interval, quantity = 0, time_frame = '', chart_type = 'candlestick',
    stop_loss = 0, take_profit = 0, trading_start_time = '00:00', trading_stop_time = '23:59',
    entry_logic, exit_logic, tpsl_type, max_allocation, position_sizing_type, position_qty: qty,
    action_str, action_str_exit, action_type, candleInterval, stopLoss = 0, takeProfit = 0,
  } = algoObj
  let amount = ''
  let exitStr = ''
  let entryStr = ''
  const final_tp = take_profit || takeProfit
  const final_sl = stop_loss || stopLoss
  const final_postion_type = position_type || positionType
  let final_entry_str = action_str
  let final_exit_str = action_str_exit
  let final_action_type = action_type
  const final_time_frame = candleInterval ? candle_map[candleInterval] : (time_frame || ip_interval)
  let interval = ''
  if (typeof final_postion_type === 'number') {
    final_entry_str = entry_logic || action_str
    final_exit_str = exit_logic || action_str_exit
    final_action_type = final_postion_type === 1 ? 'BUY' : 'SELL'
  }
  if (typeof final_postion_type === 'string') {
    final_entry_str = entry_logic || action_str
    final_exit_str = exit_logic || action_str_exit
    final_action_type = final_postion_type
  }
  final_action_type = final_action_type || 'BUY'
  const f_quantity = qty || quantity
  try {
    if (max_allocation) {
      let maxAllocationLabel = 'max allocation'
      if (position_sizing_type === POSITION_SIZING_TYPE.RISK_BASED) { maxAllocationLabel = 'max SL per trade' }
      amount = `with ${maxAllocationLabel} ${max_allocation}`
    } else {
      amount = Math.abs(f_quantity) > 1 ? `${f_quantity} shares` : '1 share'
    }
    const userAction = final_action_type.toUpperCase()
    const userExit = final_action_type.toUpperCase() === 'BUY' ? 'SELL' : 'BUY'
    interval = final_time_frame.toLowerCase()
    const { slLabel: sl, tpLabel: tp } = getAdvancedParamsLabel({ tpsl_type })
    const slLabel = sl.toLowerCase()
    const tpLabel = tp.toLowerCase()
    entryStr = final_entry_str ? `${userAction} ${amount} when ${final_entry_str} at ${interval} candle interval using ${chart_type} chart.
\nEnter trade between ${trading_start_time} to ${trading_stop_time}` : ''
    if (final_exit_str) {
      exitStr = `${userExit} ${amount} when ${final_exit_str} or at ${slLabel} of ${final_sl} or \
${tpLabel} of ${final_tp} at ${interval} candle interval using ${chart_type} chart.`
    } else if (final_sl || final_tp) {
      exitStr = `${userExit} ${amount} at ${slLabel} of ${final_sl} \
or ${tpLabel} of ${final_tp} at ${interval} candle interval using ${chart_type} chart.`
    }
  } catch (err) {
    console.log('summary generator error', err)
    return {
      entryStr: '', exitStr: '', interval: '', quantity: 1,
    }
  }
  return {
    entryStr, exitStr, interval, quantity: f_quantity,
  }
}

export const getAdvancedParamsLabel = ({ tpsl_type }) => {
  let tpLabel = 'Target profit %'
  let slLabel = 'Stop loss %'
  switch (tpsl_type) {
    case 'abs':
      tpLabel = 'Total Target profit'
      slLabel = 'Total Stop loss'
      break
    case 'pts':
      tpLabel = 'Target profit'
      slLabel = 'Stop loss'
      break
    default: break
  }
  return {
    tpLabel,
    slLabel,
  }
}

export const completeAlgoSummary = (algoObj = {}) => {
  const {
    start_time: dt_start = '',
    stop_time: dt_stop = '',
    algo_name,
    chart_type = 'candlestick',
    updated_at,
    algo_desc,
    holding_type,
    order_type = 'MARKET',
  } = algoObj
  const {
    entryStr, exitStr, interval, quantity,
  } = formEntryExitString(algoObj)

  return {
    entryStr,
    exitStr,
    deployTime: dt_stop || dt_start || updated_at,
    algoName: algo_name,
    algo_name,
    chart_type,
    interval,
    quantity,
    defaultQuantity: quantity,
    algo_desc: algo_desc === 'null' ? '' : algo_desc,
    holding_type: holding_type || holding_type_map.MIS,
    order_type,
  }
}

// export const getSegSymFromDC = (pair) => {
//   let segSym = pair
//   let isDyc = false
//   if (pair.includes('DYNAMIC CONTRACT')) {
//     [segSym] = pair.split('(')[1].split(',')
//     isDyc = true
//   }
//   return { segSym, isDyc }
// }

export const error_msg = 'Error getting response'

export const setLocalStorage = (item, convert) => {
  Object.keys(item).forEach((key) => {
    localStorage.setItem(key, convert ? JSON.stringify(item[key]) : item[key])
  })
}


export const setCookie = (obj_to_save, expiryTime) => {
  const keys = Object.keys(obj_to_save)
  let expiry = new Date(Date.now() + 36000000).toUTCString()
  if (expiryTime) {
    expiry = expiryTime
  }
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj_to_save[key]
    document.cookie = `${key}=${value};expires=${expiry};path=/;`
  }
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const deleteLocalStorage = (keyArr, clearAll) => {
  if (clearAll) {
    localStorage.clear()
    return
  }
  keyArr.forEach(item => localStorage.removeItem(item))
}

export const segSymConverter = (pair, reverse) => { // reverse true if sym_seg instead of seg_sym
  let modPair = pair
  let seg_sym = pair
  let symbol = ''
  let segment = ''
  if (typeof pair === 'string') {
    modPair = modPair.replace(':', '_')
    if (pair.includes('DYNAMIC CONTRACT')) {
      segment = ''
      symbol = modPair.replace('_', '')
      return { segment, symbol, seg_sym: pair }
    }
    if (reverse) {
      ([symbol, segment = ''] = modPair.split('_'))
    } else {
      ([segment = '', symbol] = modPair.split('_'))
    }
    if (segment === 'CDS') {
      segment = 'CDS-FUT'
    } else if (segment === 'NFO') {
      if(symbol.slice(-3) === 'FUT') segment = 'NFO-FUT'
      else segment = 'NFO-OPT'
    } else if (segment === 'MCX-FUT') {
      segment = 'MCX'
    }
    modPair = { segment, symbol }
  } else {
    ({ segment = '', symbol } = pair)
  }
  seg_sym = `${segment.toUpperCase()}_${symbol}`
  return { ...modPair, seg_sym }
}
