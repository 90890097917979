import {
  GET_INSTRUMENTS_INIT,
  GET_INSTRUMENTS_SUCCESS,
  GET_INSTRUMENTS_FAILURE,
  UPDATE_TICKS,
  SUBSCRIBE_TOKENS,
  REPLACE_SUBSCRIBED_TOKENS,
  UNSUBSCRIBE_TOKENS,
} from './actionTypes'

import { getTokenKeysFromTokens } from '../Instruments/actions'

const initialState = {
  isFetchingInstruments: false,
  unSubscribe: false,
  instrumentsError: false,
  seg_sym_list: {},
  importBasketList: [],
  tokens: [],
  ticks: {},
  screenSymMap: {},
  unSubList: [],
}

const instruments = (state = initialState, action) => {

  switch (action.type) {
    case GET_INSTRUMENTS_INIT:
      return {
        ...state,
        // tokens: [],
        unSubList: [],
        importBasketList: [],
        isFetchingInstruments: true,
        instrumentsError: false,
      }

    case GET_INSTRUMENTS_SUCCESS: {
      const { screenSymMap, seg_sym_list } = state
      const { data: { data }, screenName } = action
      const mod_list = { ...seg_sym_list }
      let segSymList = []
      const tokens = []
      let modScreenSym = { ...screenSymMap }
      for (let i = 0; i < data.length; i++) {
        const item = data[i]

        let instrument_token
        let segment
        let symbol
        if(Array.isArray(item)) {
          [segment, symbol] = item
          if(typeof segment === 'string' && typeof symbol === 'string') {
            instrument_token = `${segment.toUpperCase()}_${symbol.toUpperCase()}`
          }
        } else {
          ({ instrument_token, segment, symbol } = item)
        }
        const seg_sym = `${segment}_${symbol}`
        if (!(seg_sym in mod_list)) {
          mod_list[seg_sym] = instrument_token
        }
        tokens.push(instrument_token)
        segSymList.push(seg_sym)
      }
      if(modScreenSym[screenName]) {
        // set -> array to remove duplicate entries
        const segSymSet = new Set([...modScreenSym[screenName], ...segSymList])
        segSymList = [...segSymSet]
      }
      if (screenName) {
        modScreenSym = {
          ...modScreenSym,
          [screenName]: segSymList,
        }
      }

      return {
        ...state,
        isFetchingInstruments: false,
        seg_sym_list: mod_list,
        importBasketList: data,
        tokens,
        screenSymMap: modScreenSym,
      }
    }

    case GET_INSTRUMENTS_FAILURE: {
      return {
        ...state,
        isFetchingInstruments: false,
        instrumentsError: true,
      }
    }

    case UPDATE_TICKS: {
      const { ticks } = action
      return {
        ...state,
        ticks,
      }
    }

    case SUBSCRIBE_TOKENS: {
      const { tokens } = action
      return {
        ...state,
        tokens,
        unSubscribe: false,
      }
    }

    // this is non sync as some seg_sym might not get unsubscribe. unsubscribe code is not put
    // with subscribe code as do not want delay while subscribing
    case UNSUBSCRIBE_TOKENS: {
      // can pass screenname to delete tokens and screen
      // from subscription or can pass list of seg_sym
      // which you want to subscribe
      // pass logout true to unsubscribe all
      const { screenName, logout, unSubPairList = [] } = action

      const unSubscribe = !!logout
      // logout called
      if (unSubscribe) {
        return {
          ...initialState,
        }
      }

      const { seg_sym_list, screenSymMap } = state
      const unSubList = []

      const mod_list = { ...seg_sym_list }
      let seySymArr = [...unSubPairList]
      if (screenName && screenSymMap[screenName]) {
        seySymArr = new Set([...seySymArr, ...screenSymMap[screenName]])
        seySymArr = Array.from(seySymArr)
        delete screenSymMap[screenName]
      }
      const keys = Object.keys(screenSymMap)
      seySymArr.forEach((seg_sym) => {
        let exist = false
        for (let i = 0; i < keys.length; i++) {
          const screenTokens = screenSymMap[keys[i]]
          if (screenTokens.includes(seg_sym)) {
            exist = true
            break
          }
        }
        if (!exist && mod_list[seg_sym]) {
          unSubList.push(mod_list[seg_sym])
          delete mod_list[seg_sym]
        }
      })

      return {
        ...state,
        unSubList,
        unSubscribe,
        seg_sym_list: mod_list,
      }
    }

    case REPLACE_SUBSCRIBED_TOKENS: {
      const { addTokens = [], removeTokens = [] } = action
      const tokens = state.tokens || []
      const prevTokens = [...tokens]
      let finalTokens = prevTokens
      let finalTokenKeys = getTokenKeysFromTokens(finalTokens)
      if (Array.isArray(addTokens) && Array.isArray(removeTokens)) {
        const afterTokensRemoved = prevTokens.filter((token) => {
          return !removeTokens.includes(token)
        })
        const addedReplaceTokens = [...addTokens, ...afterTokensRemoved]
        finalTokens = addedReplaceTokens
        finalTokenKeys = getTokenKeysFromTokens(finalTokens)
      }
      return {
        ...state,
        tokens: finalTokens,
        token_keys: finalTokenKeys,
        kind: 'subscribe',
      }
    }

    default:
      return state
  }
}

export default instruments
