import { UPDATE_TICKS, SUBSCRIBE, UNSUBSCRIBE, REPLACE_SUBSCRIBED_TOKENS } from './actionTypes'
import { getTokenKeysFromTokens } from './actions'

const initialTokenkeys = {
  'coinbase_BTC/USD': 1,
  'coinbase_ETH/USD': 1
}

const initialTokens = Object.keys(initialTokenkeys)

const initialState = {
  ticks: {},
  tokens: [...initialTokens],
  token_keys: { ...initialTokenkeys },
  kind: 'subscribe'
}

const ltpTicks = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TICKS: {
      const { ticks } = action
      return {
        ...state,
        ticks
      }
    }

    case SUBSCRIBE: {
      return {
        ...state,
        tokens: [...action.tokens, ...initialTokens],
        token_keys: { ...action.token_keys, ...initialTokenkeys },
        kind: action.kind
      }
    }

    case UNSUBSCRIBE:
      return {
        ...initialState
      }

      case REPLACE_SUBSCRIBED_TOKENS: {
        const { addTokens = [], removeTokens = [] } = action
        const tokens = state.tokens || []
        const prevTokens = [...tokens]
        let finalTokens = prevTokens
        let finalTokenKeys = getTokenKeysFromTokens(finalTokens)
        if (Array.isArray(addTokens) && Array.isArray(removeTokens)) {
          const afterTokensRemoved = prevTokens.filter((token) => {
            return !removeTokens.includes(token)
          })
          const addedReplaceTokens = [...addTokens, ...afterTokensRemoved]
          finalTokens = addedReplaceTokens
          finalTokenKeys = getTokenKeysFromTokens(finalTokens)
        }
        return {
          ...state,
          tokens: finalTokens,
          token_keys: finalTokenKeys,
          kind: 'subscribe',
        }
      }

    default:
      return state
  }
}

export default ltpTicks
