import { GET_USER_DETAILS_INIT, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE } from './actionTypes'

export const initialState = {
  isFetchingUser: false,
  user_details: {},
  userDetailsError: false,
  userDetailsErrorMsg: ''
}

const welcome = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_INIT:
      return {
        ...state,
        isFetchingUser: true,
        userDetailsError: false
      }

    case GET_USER_DETAILS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        user_details: data,
        isFetchingUser: false
      }
    }

    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingUser: false,
        userDetailsError: true,
        userDetailsErrorMsg: action.error
      }

    default:
      return state
  }
}

export default welcome
