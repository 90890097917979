import { GET_USER_DETAILS_INIT, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE } from './actionTypes'

export function getUserDetailsSuccess(data) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    data
  }
}

export function getUserDetailsFailure(error) {
  return {
    type: GET_USER_DETAILS_FAILURE,
    error
  }
}

export function getUserDetails(params, headers) {
  const payload = {
    params,
    headers
  }
  return {
    type: GET_USER_DETAILS_INIT,
    payload
  }
}
