import { all, fork } from 'redux-saga/effects'
import * as authSagas from './containers/Auth/Auth-saga'
import * as tvchartSagas from './containers/Tvchart/Tvchart-saga'
import * as commonSagas from './containers/Common/Common-saga'
import * as instrmentsSagas from './containers/Instruments/Instruments-saga'

const auth = Object.values(authSagas).map((saga) => {
  return fork(saga)
})

const tvchart = Object.values(tvchartSagas).map((saga) => {
  return fork(saga)
})

const common = Object.values(commonSagas).map((saga) => {
  return fork(saga)
})

const instruments = Object.values(instrmentsSagas).map((saga) => {
  return fork(saga)
})



export default function* root() {
  yield all([...auth, ...tvchart, ...common, ...instruments])
}
