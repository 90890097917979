export const CHANGE_INTERVAL = 'CHANGE_INTERVAL'
export const CHANGE_SYMBOL = 'CHANGE_SYMBOL'

export const FETCH_BACKTEST_CHART = 'FETCH_BACKTEST_CHART'
export const FETCH_BACKTEST_CHART_SUCCESS = 'FETCH_BACKTEST_CHART_SUCCESS'
export const FETCH_BACKTEST_CHART_FAILUE = 'FETCH_BACKTEST_CHART_FAILUE'

export const RUN_BACKTEST = 'RUN_BACKTEST'

export const SET_SYMBOL_AND_SUBSCRIBE = 'SET_SYMBOL_AND_SUBSCRIBE'
