import React from 'react'

// inline styles [ doesn't support media queries ]
const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'var(--bg-color)'
  },
  text: {
    color: 'var(--fg-color)'
  }
}

const ErrorPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.text}>Something Went Wrong</h1>
      <h3 style={styles.text}>Please Try Again Later</h3>
    </div>
  )
}

export default ErrorPage
