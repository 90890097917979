/* eslint-disable import/prefer-default-export */
import { takeLatest, put, call } from 'redux-saga/effects'
import { endpoints, baseTechURL, baseWorldURL, openStreakURL } from '../../utils/apiEndpoints'
import { getRequest } from '../../utils/apiRequests'
import { getUserDetailsSuccess, getUserDetailsFailure } from './actions'
import { GET_USER_DETAILS_INIT } from './actionTypes'
import { STREAK_PLATFORM } from '../../utils/constants'

const error_msg = 'Error Getting Response'

function* getUserDetails(action) {
  try {
    const { params, headers } = action.payload
    const { platform = '' } = params
    const baseUrl = platform === STREAK_PLATFORM.INDIA ? baseTechURL : STREAK_PLATFORM.OPENSTREAK ? openStreakURL : baseWorldURL
    const url = `${baseUrl}${endpoints.get_session_status}`
    const data = yield call(getRequest, url, {}, headers)
    if (data.status === 'success') {
      yield put(getUserDetailsSuccess(data))
    } else {
      yield put(getUserDetailsFailure(data.error_msg || error_msg))
    }
  } catch (err) {
    yield put(getUserDetailsFailure(error_msg))
  }
}

export function* getUserDetailsSaga() {
  yield takeLatest(GET_USER_DETAILS_INIT, getUserDetails)
}
