export const endpoints = {
  // REMEMBER :- ALWAYS ADD '/' in the end of any ENDPOINT
  // auth
  get_session_status: 'get_session_status/',
}

export const fetchCandlesURL = {
  india: 'https://datastore.streak.tech/candles/fetch/', // also referred as tech platform
  world: 'https://datastore.streak.world/candles/fetch/',
  openStreak: 'https://datastore-op.streak.tech/candles/fetch/',
}

export const baseTechURL = 'https://api.streak.tech/'
export const openStreakURL = 'https://api-op.streak.tech/' // 'https://api-op.streak.tech/'
export const baseWorldURL = 'https://api.streak.world/' // api.c.streak.ninja api.streak.world

// this api is only for world
export const symbolsBackendUrl = 'https://ams.c.streak.ninja/accounts/symbols/'

export const fetchTechCandles = 'https://databeast.streak.tech/candle-server/candles'

export const searchCandlesInTech = 'https://databeast.streak.tech/candle-server/basic-search-charts'

export const symbolResolveTech = 'https://databeast.streak.tech/candle-server/symbol-info'

export const fetchBacktestChart = 'fetch_backtest_chart/'
export const fetchMarketplaceChart = 'fetch_marketplace_chart/'

export const baseUrl = 'https://mo.streak.tech/api/'

export const symbolIconsUrl = 'https://streak-in-public-static.s3.ap-south-1.amazonaws.com/'
export const notifUrl = 'https://notif.streak.tech/notification'

export const pollUrl = 'https://notif.streak.tech/handle_post/'
export const newPollUrl = 'https://ua.streak.tech/handle_post/'
