import { takeLatest, put, call } from 'redux-saga/effects'
import { endpoints, baseUrl, newPollUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  profileCarouselSuccess,
  profileCarouselFailure,
} from './actions'
import {
  FETCH_PROFILE_CAROUSEL,
  POLL_USER,
} from './actionTypes'

import { error_msg } from '../../utils/constants'

function* fetchProfileCarousel(action) {
  try {
    const { headers } = action
    const url = `${baseUrl}${endpoints.profile_carousel}`
    const data = yield call(getRequest, url, {}, headers)
    if (!data.error && data.status === 'success') {
      yield put(profileCarouselSuccess(data))
    } else {
      yield put(profileCarouselFailure(data.error))
    }
  } catch(err) {
    yield put(profileCarouselFailure(err.message))
  }
}

export function* fetchProfileCarouselSaga() {
  yield takeLatest(FETCH_PROFILE_CAROUSEL, fetchProfileCarousel)
}

function* pollUser(action) {
  try {
    const { headers, params } = action
    const url = `${newPollUrl}`
    yield call(postRequest, url, params, headers, false)
  } catch(err) {
    yield put(profileCarouselFailure(err.message || error_msg))
  }
}

export function* pollUserSaga() {
  yield takeLatest(POLL_USER, pollUser)
}
