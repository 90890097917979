import msgpack from 'msgpack-lite'
import {
  CHANGE_INTERVAL,
  CHANGE_SYMBOL,
  FETCH_BACKTEST_CHART,
  FETCH_BACKTEST_CHART_FAILUE,
  FETCH_BACKTEST_CHART_SUCCESS,
  RUN_BACKTEST,
  SET_SYMBOL_AND_SUBSCRIBE,
} from './actionTypes'
import { fetchCandlesURL } from '../../utils/apiEndpoints'
import { STREAK_PLATFORM } from '../../utils/constants'

export const changeInterval = (interval) => {
  return {
    type: CHANGE_INTERVAL,
    interval
  }
}

// symbol == ( streak's ) seg:sym
export const changeSymbol = (symbol, params) => {
  return {
    type: CHANGE_SYMBOL,
    symbol,
    params
  }
}

export const setSymbolAndSubscribe = ({ dispatch, symbol }) => {
  return {
    type: SET_SYMBOL_AND_SUBSCRIBE,
    dispatch,
    symbol,
  }
}


export const ctbrunBacktest = (params, headers) => {
  return {
    type: RUN_BACKTEST,
    params,
    headers
  }
}

/**
 *
 *
 * @param {*} {
 *   exchange, // UPPERCASE eg: 'BINANCE'
 *   symbol, // UPPERCASE eg: "BTC/USD",
 *   startTime, // unix-timestamp eg: 1593903355,
 *   endTime, // unix-timestamp eg: 1594813015,
 *   timePeriod // smallcase eg: "min" use constant,
 * }
 * @returns
 *  Promise -> candleData
 */

export const fetchCandles = ({ exchange, symbol, startTime, endTime, timePeriod, platform, csrf, sessionid }) => {
  const payload = {
    exchange,
    symbol,
    start_time: startTime,
    end_time: endTime,
    time_period: timePeriod,
    source: 'charts',
    csrf,
    session_id: sessionid
  }

  const url = platform === STREAK_PLATFORM.INDIA ? fetchCandlesURL.india : STREAK_PLATFORM.OPENSTREAK ? fetchCandlesURL.openStreak : fetchCandlesURL.world
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(async (response) => {
      try {
        // msgpack encoded arrayBuffer is converted back to JSON
        const res = await response.arrayBuffer()
        const codec = msgpack.createCodec({ useraw: true })
        const barray = new Uint8Array(res)
        const actualData = []
        const dt = await msgpack.decode(barray, { codec })
        for (let i = 0; i < dt.length; i += 1) {
          const cdl = msgpack.decode(dt[i], { codec })
          actualData.push(cdl)
        }
        if (actualData.length) {
          resolve(actualData)
        }
        reject(new Error('No Data'))
      } catch (error) {
        reject(new Error('No Data'))
      }
    })
  })
}

export const fetchBacktestDataSuccess = (data) => {
  return {
    type: FETCH_BACKTEST_CHART_SUCCESS,
    data
  }
}

export const fetchBacktestDataFailure = (error) => {
  return {
    type: FETCH_BACKTEST_CHART_FAILUE,
    error
  }
}

export const fetchBacktestData = (params, headers, isDiscover) => {
  const payload = {
    params,
    headers,
    isDiscover
  }
  return {
    type: FETCH_BACKTEST_CHART,
    payload
  }
}
