import { combineReducers, applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'
import tvchart from './containers/Tvchart/Tvchart-reducer'
import ticks from './containers/Ticks/Ticks-reducer'
import auth from './containers/Auth/Auth-reducer'
import common from './containers/Common/Common-reducer'
import instruments from './containers/Instruments/Instruments-reducer'



const appReducer = combineReducers({
  tvchart,
  ticks,
  auth,
  common,
  instruments,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

let enhancer

const sagaMiddleware = createSagaMiddleware()

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(sagaMiddleware)
} else {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          maxAge: 25
        })
      : compose
  enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
}

const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
