/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'

import WebWorker from '../../utils/web.worker'

import { withTheme } from '../../Theme/ThemeProvider'


class Common extends Component {
  constructor(props) {
    super(props)

    this.state = {
      app_state: 'active',
    }
    this.tokenRegisterCount = 0
    this.pollUserTimer = null
    this.alert = false
    this.analytics = {}
    this.thread = {}
    this.audioRef = React.createRef()
    this.notif_connected = false
  }

  componentDidMount() {
    const { user_details = {}, isWebView } = this.props
    const { user_uuid } = user_details
    this.thread = new WebWorker()
    if (this.thread) this.thread.addEventListener('message', event => this.tickHandler(event))
    this.initializeApp()
  }

  componentDidUpdate = (prevProps) => {
    const {
      tokens,
      unSubscribe,
      unSubList,
      location,
    } = this.props
    if (!unSubscribe) {
      if (!isEqual(tokens, prevProps.tokens) && tokens.length) {
        this.thread.postMessage({ type: 'subscribe', instrument_list: tokens })
      }
      if (!isEqual(unSubList, prevProps.unSubList) && unSubList.length) {
        this.thread.postMessage({ type: 'unsubscribe', instrument_list: unSubList })
      }
    } else if (unSubscribe !== prevProps.unSubscribe && unSubscribe) {
      this.thread.postMessage({ type: 'unsubscribe' })
    }

    if (!isEqual(location, prevProps.location) && !this.notif_connected) {
      // this.connectToNotif()
    }
  }

  componentWillUnmount = () => {
    if (this.thread) {
      this.thread.postMessage({
        type: 'close',
      })
    }
    if (this.pollUserTimer) clearInterval(this.pollUserTimer)
  }

  connectToNotif = () => {
    const { user_details = {} } = this.props
    const { user_uuid } = user_details
    // const isCtb = isCtbFlow()

    // if (!isCtb) {
    //   this.thread.postMessage({
    //     type: 'notif-connect',
    //     user_uuid,
    //   })
    // }
  }

  playSound = () => {
    this.notifSound.play()
  }

  initializeApp = async () => {
    const {
      user_details = {}, shouldRequestDemo, location,
    } = this.props
    const {
      subscription_plan = '', subscription_valid, user_uuid, access_token,
      broker_api_key, broker,
    } = user_details
    // if (user_uuid) {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ user_uuid })
    }
    const wsBroker = 'ab'
    this.thread.postMessage({
      type: 'connect',
      access_token,
      broker_api_key,
      broker: wsBroker,
    })
    // this.connectToNotif()
    if (this.pollUserTimer) clearInterval(this.pollUserTimer)
    if (process.env.NODE_ENV !== 'development') {
      this.pollUserTimer = setInterval(() => this.pollHandler(user_uuid), 30000)
    }
  }

  tickHandler = (event = {}) => {
    const {
      updateTicks, updateNotifications, unSubscribe, seg_sym_list,
      getNotifications, getDeployedCount, isFetchingNotifications,
    } = this.props
    const { data } = event
    if (!unSubscribe) {
      if (data === 'Connected!!') {
        this.connected = true
        return
      }
      if (data.constructor.name === 'Object' && this.connected
        && !data.notification_uuid) {
        updateTicks(data) // updateLTPTicks
        return
      }

      if (data.inlcudes === 'Closed!!') {
        // const tokens = Object.values(seg_sym_list)
        this.connected = false
        // if (tokens.length && this.thread) {
        //   this.thread.postMessage({ type: 'subscribe', instrument_list: tokens })
        // }
      }
    }
    if (data === 'notif service connected') {
      this.notif_connected = true
    }
  }


  pollHandler = (user_uuid) => {
    const { pollUser, location } = this.props
    const params = {
      user_uuid,
      view: location.pathname,
    }
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    pollUser(params, headers)
  }

  render() {
    return (
      <div />
    )
  }
}

export default withTheme({})(Common)
