import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { headerGenerator } from './utils/common'
import { getUserDetails } from './containers/Auth/actions'
import ErrorPage from './UI/ErrorPage'
import Common from './containers/Common/Common-redux'
import Loader from './UI/Loader'

const Routes = lazy(() => import('./Routes'))

class App extends Component {
  constructor(props) {
    super(props)
    const { location } = props
    this.queryObj = {}
    if (location.search) {
      const queries = location.search.slice(1).split('&')
      queries.map((str) => {
        const [key, value] = str.split('=')
        this.queryObj[key] = value
        return null
      })
    }
    const { theme } = this.queryObj
    if (theme === 'dark') {
      document.body.setAttribute('data-theme', 'dark')
    }
  }

  componentDidMount = () => {
    const { getUserDetails: fetchUser } = this.props
    const { csrf = '', sessionid = '', platform = '' } = this.queryObj
    const headers = headerGenerator(csrf, sessionid)
    fetchUser({ platform }, headers)
  }

  render() {
    const { userDetailsError = false, location } = this.props
    // if (userDetailsError) {
    //   return <ErrorPage />
    // }

    return (
      <Suspense fallback={<Loader />}>
        <Common isWebView location={location} />
        <Routes />
      </Suspense>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: (params, headers) => dispatch(getUserDetails(params, headers))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
