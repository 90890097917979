import {
  CHANGE_SYMBOL,
  CHANGE_INTERVAL,
  FETCH_BACKTEST_CHART,
  FETCH_BACKTEST_CHART_SUCCESS,
  RUN_BACKTEST
} from './actionTypes'

const initialState = {
  chartState: {},
  symbol: '',
  tvChartRef: null,
  interval: '5',
  theme: 'light', // Only for tradingView-lite
  fetchingTradeLogs: false,
  tradeLog: [],
  pnl: [],
  fetchTradeLogErr: null,
  startTime: null,
  endTime: null
}

const tvchart = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_INTERVAL: {
      const { interval } = action
      return {
        ...state,
        interval
      }
    }
    case CHANGE_SYMBOL: {
      const extraParams = action.params && typeof action.params === 'object' ? action.params : {}
      return {
        ...state,
        symbol: action.symbol, // symbol <==> ( streak's ) seg:sym
        ...extraParams
      }
    }
    case FETCH_BACKTEST_CHART: {
      return {
        ...state,
        fetchingTradeLogs: true,
        tradeLog: [],
        fetchTradeLogErr: null
      }
    }
    case FETCH_BACKTEST_CHART_SUCCESS: {
      const { data } = action
      return {
        ...state,
        fetchingTradeLogs: false,
        tradeLog: data.trade_log,
        pnl: data.pnl,
        fetchTradeLogErr: null
      }
    }

    case RUN_BACKTEST: {
      return {
        ...state
      }
    }
    default:
      return state
  }
}

export default tvchart
